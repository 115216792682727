<template>
  <v-container>
    <common-loading
      :title="loading.title"
      :message="loading.message"
      :loading_dialog="loading.dialog"
      :show_loading="loading.show_loading"
      :show_confirm="loading.show_confirm"
      v-on:confirm="loading.dialog = false"
    ></common-loading>
    <v-row class="justify-center d-flex">
      <v-col lg="6" md="6" class="mx-auto">
        <v-card class="card-shadow border-radius-xl">
          <div class="card-padding text-center">
            <h5 class="text-h5 text-typo font-weight-bold">
              {{ $t("Sign In") }}
            </h5>
          </div>
          <div class="card-padding">
            <v-text-field
              hide-details
              outlined
              background-color="rgba(255,255,255,.9)"
              color="rgba(0,0,0,.6)"
              light
              height="40"
              placeholder="帳號或Email或手機號碼"
              class="
                input-style
                font-size-input
                border border-radius-md
                placeholder-lighter
                text-color-light
                mb-4
              "
              v-model="username"
              @keydown.enter="loginAccount"
            >
            </v-text-field>

            <v-text-field
              hide-details
              outlined
              background-color="rgba(255,255,255,.9)"
              color="rgba(0,0,0,.6)"
              light
              height="40"
              placeholder="Password"
              class="
                input-style
                font-size-input
                border border-radius-md
                placeholder-lighter
                text-color-light
                mb-4
              "
              v-model="password"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="showPassword = !showPassword"
              :type="showPassword ? 'text' : 'password'"
              @keydown.enter="loginAccount"
            >
            </v-text-field>

            <v-btn
              elevation="0"
              :ripple="false"
              height="43"
              class="
                font-weight-bold
                text-uppercase
                btn-default
                bg-gradient-default
                py-2
                px-6
                me-2
                mt-6
                mb-2
                w-100
              "
              color="#5e72e4"
              small
              @click="loginAccount"
              >{{ $t("Sign In") }}</v-btn
            >
          </div>
          <div class="mt-2 mb-2 position-relative text-center">
            <p class="text-body ma-2">如忘記密碼 請洽管理員 V:{{ version }}</p>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
// import VueJwtDecode from "vue-jwt-decode";
import { version } from "../../../../package.json";
import { mapState, mapActions } from "vuex";
import CommonLoading from "@/views/Pages/General/Widgets/CommonLoading.vue";
export default {
  name: "sign-up-basic",
  data() {
    return {
      // checkbox: false,
      showPassword: false,
      username: null,
      password: null,
      // username: "kuhsuanchieh@17000.com.tw",
      // username: "kent@thk.com",
      // password: "123456",
      loading: {
        dialog: false,
        title: null,
        message: null,
        show_loading: true,
        show_confirm: false,
      },
      version: version,
    };
  },
  components: {
    CommonLoading,
  },
  computed: {
    ...mapState(["token"]),
  },
  methods: {
    ...mapActions(["submitToken"]),
    loginAccount() {
      if (this.username == null || this.username.length == 0) {
        this.loading = {
          dialog: true,
          title: "訊息",
          message: "請輸入帳號",
          show_loading: false,
          show_confirm: true,
        };
        return;
      }
      this.loading = {
        dialog: true,
        title: "登入中",
        message: "請稍後",
        show_loading: true,
        show_confirm: false,
      };
      // console.log("this.$route.query.redirect ", this.$route.query.redirect);
      if (this.username.length > 0 && this.password.length > 0) {
        let url = process.env.VUE_APP_SERVER_URL + "/api/v1/login/access-token";
        // console.log(" process.env.VUE_APP_SERVER_URL" + url);
        let currentObj = this;
        // TO application/x-www-form-urlencoded
        var params = new URLSearchParams();
        params.append("username", this.username);
        params.append("password", this.password);
        this.axios
          .post(url, params)
          .then(function (response) {
            currentObj.submitToken(response.data);
            if (response.data["cpwd"] == true) {
              currentObj.toWizard();
            } else {
              currentObj.toCustomerTable();
            }
          })
          .catch(function (error) {
            if (error.response == null || error.response == undefined) {
              currentObj.showError("無法登入 請稍後再試");
              return;
            }
            if (error.response) {
              // currentObj.showError("帳號或密碼錯誤");
              console.log("error.response");
              if (
                error.response.status == 400 &&
                error.response.data.detail == "Inactive user"
              ) {
                currentObj.showError("帳號失效 請洽管理員");
                return;
              }
            }
            if (error.response.data && error.response.data.detail) {
              if (
                error.response.data.detail.includes(
                  "Incorrect username,mobile,email or password"
                )
              ) {
                currentObj.showError("密碼錯誤");
                return;
              }
            }
            currentObj.showError("無法登入 請稍後再試");
          })
          .finally(() => {});
      } else {
        this.showError("請輸入帳號密碼");
      }
    },
    showError(message) {
      this.loading.message = message;
      this.loading.show_loading = false;
      this.loading.show_confirm = true;
      this.loading.dialog = true;
    },
    toCustomerTable() {
      // get the redirect query param if any
      const redirect = this.$route.query.redirect;
      if (redirect != null && redirect.includes("/login")) {
        this.$router.push({ name: "Dashboard" });
      } else {
        this.$router.push(redirect || { name: "Dashboard" });
      }
    },
    toWizard() {
      this.$router.push({ name: "CRMWizard" });
    },
  },
};
</script>
